import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main/main.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FlightbookingComponent } from './flightbooking/flightbooking.component';
import { BgMoreComponent } from './background/bg-more/bg-more.component';

 export const routes: Routes = [
  { path: '',redirectTo:'index',pathMatch:'full' },
  { path: 'index',component:MainComponent },
  { path: 'privacyPolicy',component:PrivacyPolicyComponent },
  { path: 'terms&condition',component:TermsConditionComponent },
  { path: 'flight',component:FlightbookingComponent },
  { path: 'aboutUs', component:BgMoreComponent},
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(
      routes,
      {
      // Tell the router to use the HashLocationStrategy. 
      useHash: true, 
      enableTracing: false, 
      }
      )
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }