<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Akinbode Abdulrazaq Opeyemi <br/>(Head Software Development, Design/Architecture)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Abdulrazaq  Opeyemi (PGD, B.SC, IADNCS, IDITC, ML, CV).</strong> He is a mobile app. developer/software engineer, 
        having developed his programming skills and wide range of experience in the field. 
        He has an International Diploma in Information Technology and Communication and an International advanced diploma 
        in network and cyber security. 
    </p>

    <p>
        He has a Bachelor of Science (B.Sc.) in Computer Science and a Post-Graduate Diploma in Criminology from Bayero University, Kano. 
        Akinbode Abdulrazaq Opeyemi is a registered professional mobile developer/software engineer in Damat & Sons Global limited and has more than 5 years of experience in the field as a developer and has developed a couple of mobile apps. 
    </p>
    
    <p>
        Akinbode Abdulrazaq Opeyemi is also a cyber security specialist and professionally well educated in desktop publishing, computer hardware and software maintenance. 
        He has a passion in machine learning and its application. He is striving to be the best, not necessarily the first in all that he chooses to do, and contributing his quota to the sustainable development of indigenous solutions.
        
    </p>

</mat-dialog-content>