<div class="mask rgba-black-strong waves-light">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-sm-12">

                    <div class="row">
                        <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12 mask rgba-black-strong waves-light" mdbWavesEffect >
                        <h2 class="white-text h2 h2-reponsive font-up font-bold mb-3 wow fadeInDown" data-wow-delay="0.3s"><strong>BOARD OF DIRECTORS</strong></h2>
                        <hr class="hr-strong mt-4 wow fadeInDown white" data-wow-delay="0.4s">
                        </div>   
                    </div>
<div class="wow lightSpeedIn">
                <div class="row pt-2">
                    <div class="col-6  text-center white-text" >
                        <img src="assets/Board/chair.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                                
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Haruna Yunusa Sa’eed</strong></h6>
                        <br/> 
                        <strong class="text-uppercase ">Chairman</strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openHys()">Read more...</button> 
                        </div>
                    </div>

                    <div class="col-6 text-center white-text" >
                        <img src="assets/Board/CEO.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180"> 
                        
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>A.S Damat</strong></h6>
                        <sub class="">(B.Sc. PHYSICS, B.Sc. COMP. SCI., TNE, OCA, OCP, PMP.)</sub> <br/> 
                        <strong class="text-uppercase ">CEO</strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openAsd()">Read more...</button> 
                        </div>
                    </div>
                </div>

                <div class="row pt-2">
                    <div class="col-6 text-center white-text" >
                        <img src="assets/Board/mem1.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                                
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Professor Sahalu B. Junaidu</strong></h6>
                        <br/> 
                        <strong class="text-uppercase ">Member</strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openPsb()">Read more...</button> 
                        </div>
                </div>
            
                <div class="col-6 text-center white-text" >
                        <img src="assets/Board/Director.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                        
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Ahmed Yusuf</strong></h6>
                        <sub class="">(B.Sc., M.Sc., PMP)</sub> <br/> 
                        <strong class="text-uppercase ">Member</strong>
        
                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openAy()">Read more...</button> 
                        </div>
                </div>

                </div>

                <div class="row pt-2">
                    <div class="col-6 text-center white-text">
                        <img src="assets/Board/mem2.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                                
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Dr. Mikail Audu</strong></h6>
                         <br/> 
                        <strong class="text-uppercase ">Member</strong>
        
                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openMa()">Read more...</button> 
                        </div>
                    </div>

                </div>

            </div>
</div>
<!-- *******************************************Management Team************************************************************ -->            
            <div class="col-lg-6 col-sm-12">
                <div class="row">
                    <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12 mask rgba-black-strong waves-light" mdbWavesEffect >
                    <h2 class="white-text h2 h2-reponsive font-up font-bold mb-3 wow fadeInDown" data-wow-delay="0.3s"><strong>MANAGEMENT TEAM</strong></h2>
                    <hr class="hr-strong mt-4 wow fadeInDown white" data-wow-delay="0.4s">
                    </div>   
                </div>

<div class="wow lightSpeedIn">                
                <div class="row pt-2">
                    <div class="col-6 text-center white-text" >
                        <img src="assets/management/Opezyhick.png" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
  
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Akinbode Abdulrazaq Opeyemi</strong></h6>
                        <sub>(PGD, B.SC, IADNCS, IDITC, ML, CV)</sub><br/> 
                        <strong class="text-uppercase ">Head Software Development, Design/Architecture </strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openAa()">Read more...</button> 
                        </div>
                    </div>
            
                    <div class="col-6 text-center white-text" >
                        <img src="assets/management/AB.JPG" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">

                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>H.A Opeyemi</strong></h6>
                        <sub class="">(M.Sc. B.Sc. IAD, ID, NCS, ISVM, DP, SPI, CHSM, WNC)</sub> <br/> 
                        <strong class="text-uppercase ">Head Web Development and General Maintenance</strong>
        
                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openHa()">Read more...</button> 
                        </div>
                    </div>

                </div>

                <div class="row pt-2">

                    <div class="col-6 text-center white-text" >
                        <img src="assets/management/Zee.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180"> 

                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Zainab Abdullahi</strong></h6>
                        <sub class="">(MBA, BSc.)</sub> <br/> 
                        <strong class="text-uppercase ">Business Development/Control and Compliance</strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openZa()">Read more...</button> 
                        </div>
                    </div>

                    <div class="col-6  text-center white-text" >
                        <img src="assets/management/Yahya.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                        
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Dr. Yahaya Ibrahim</strong></h6>
                        <sub>(Ph.D, M.Sc., B.Sc.,)</sub><br/> 
                        <strong class="text-uppercase "> Director Research and Development I</strong>

                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openDyi()">Read more...</button> 
                        </div>
                    </div>
                </div>

                <div class="row pt-2">

                    <div class="col-6 text-center white-text">
                        <img src="assets/Board/mem3.jpeg" class="mb-3 img-thumbnail z-depth-1 rounded-circle"alt="" width="180" height="180">
                                
                        <h6  class=" text-uppercase" style="margin-top: -15px; margin-bottom: -7px"><strong>Dr. Salisu Aliyu</strong></h6>
                        <br/> 
                        <strong class="text-uppercase ">Director Research and Development II</strong>
        
                        <div class="d-flex justify-content-center">
                            <button mat-button (click)="openSa()">Read more...</button> 
                        </div>
                    </div>
                </div>

</div>
            </div>
        </div>
    </div>
</div>
