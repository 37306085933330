<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Dr. Mikail Audu (Board Member)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Mikail Audu,</strong> General Manager, Hybrid Feeds Limited. Dr. Mikail Audu Is a Seasoned Business Professional with Experience Gained.  
        He Hold A Doctorate Degree In Veterinary Medicine (DVM) From Usman Danfodio University, Sokoto. 
    </p>

    <p>
        Dr. MIKAIL Has Provided Strategic Leadership Driving Growth Of Agricultural Business With Good Analytical Skills And Excellent Communication Skill. 
    </p>
    
    <p>
        He Been Part Of Several Training Within Nigeria And Overseas Amongst Which Are Effective Leadership (Leap Africa), 
        Livestock And Animal Husbandry Training (China Agricultural Ministry), Agribusiness Management Course (Lagos Business School). 
        He Holds A Cochran Fellowship From University Of Mission, USA. He Is Also A Recipient Of Several Awards.
        
    </p>

</mat-dialog-content>