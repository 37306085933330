<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Zainab Abdullahi <br/>(Business Development/Control and Compliance)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong>Zainab Abdullahi,</strong> B.Sc. (Hons) Business Administration, M.Sc. Business Administration (MBA). A Graduate of Ahmadu Bello University, Zaria, Department of Business Administration.
    </p>

    <p>
        Control and Compliance Officer, Grass Root Empowerment Programme (GEP). A Seasoned Banker, with Experience in Cash Handling, Control and Compliance.
    </p>
    
    <p>
        ABDULLAHI Zainab over the years has gained experience in the Travel & Tour Industry with Mercurial Travels. She also has a vast experience in Financial Management. 
        
    </p>

</mat-dialog-content>