<!-- Display's Information Fecthed From Firestore -->
<div class="container-fluid">
        <div class="row">
                <div class="col-12 ml-auto mr-auto mb-0 text-center">
                        <h2 class="header wow fadeInUp">Our Agents</h2>
                        <h6>This agents helps you in processing your traveling documents</h6>
                </div>
        </div>

        <div class="row text-center d-flex justify-content-center">
                <div class="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-2" *ngFor="let agent of agents; index as i">
                        <app-agents [agent]='agent'></app-agents>
                </div>
        </div>



</div>