import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  title = 'TraTrust';
  logo = 'assets/favicon.png';
  
  constructor() { }

  ngOnInit() {
  }

}
