
      <div class="section section-signup page-header bg-view" >
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <div class="card card-login">
 
                  <div class="card-header card-header-danger app-bg-color text-center">
                    <h4 class="card-title header">Sign-In</h4>
                    <div class="social-line">
                      <a href="#" class="btn btn-just-icon btn-link">
                            <mdb-icon fab icon="google" size="2x"></mdb-icon>
                      </a>
                      <a href="#" class="btn btn-just-icon btn-link">
                            <mdb-icon fab icon="facebook-f" size="2x"></mdb-icon>
                      </a>
                      <a href="#" class="btn btn-just-icon btn-link">
                            <mdb-icon fab icon="twitter-square" size="2x"></mdb-icon>
                      </a>
                    </div>
                  </div>
                  <p class="description text-center">Or Join TraTrust</p>
                  <div class="card-body">
                      <div class="container">
                        <form [formGroup]="validationForm">
                                <div class="md-form">
                                  <mdb-icon fas icon="envelope" class="prefix"></mdb-icon>
                                  <input mdbInput mdbValidate formControlName="emailFormEx" minLength="8" maxLength="25" type="email"
                                         class="form-control" id="form9">
                                  <label for="form9">Type your e-mail</label>
                                  <mdb-error *ngIf="emailFormEx.invalid && (emailFormEx.dirty || emailFormEx.touched)">Input invalid</mdb-error>
                                  <mdb-success *ngIf="emailFormEx.valid && (emailFormEx.dirty || emailFormEx.touched)">Input valid</mdb-success>
                                </div>
                              
                                <div class="md-form">
                                  <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
                                  <input mdbInput mdbValidate formControlName="passwordFormEx" minLength="10" maxLength="25" type="password"
                                         class="form-control" id="form10">
                                  <label for="form10">Type your password</label>
                                  <mdb-error *ngIf="passwordFormEx.invalid && (passwordFormEx.dirty || passwordFormEx.touched)">Input invalid
                                  </mdb-error>
                                  <mdb-success *ngIf="passwordFormEx.valid && (passwordFormEx.dirty || passwordFormEx.touched)">Input valid
                                  </mdb-success>
                                </div>
                         </form>
                         </div>
                  </div>
                  <div class="footer text-center">
                    <a href="#" class="btn btn-danger btn-md">Get Started</a>
                  </div>

                  <div class="col-md-12 text-right">
                        <a routerLink="/login" fragment="" class="btn btn-link  btn-md" target="_blank">View Login Page>>></a>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
