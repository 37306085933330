<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd">Abdullahi Shuaibu Damat (C.E.O)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong>Abdullahi Shuaibu Damat, C.E.O (Tratrust Ltd.);</strong> also the CEO Damat and sons global limited, 
        Director, Dogodamat Nigerian limited, Director planning, Northwest Inter-Continental Concept (a One-Stop Auto)  
        a Programmer, a System Analyst/Software Architect, a growth-minded entrepreneur, an African market analyst and 
        player, with more than 15 years experience in the agro value chain production and distributions.   
    </p>

    <p>
        B.Sc. Computer Science: ESAE University. Diploma in telecommunication network engineering: 
        Institute for advanced electronics studies (IEAS). B.Sc.  Physics:  Ahmadu Bello University (ABU) Zaria, 
        Oracle (OCA, OCP and SQL): National Institute for Information Technology. A Project Management Professional. 
    </p>

</mat-dialog-content>