import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hys',
  templateUrl: './hys.component.html',
  styleUrls: ['./hys.component.scss']
})
export class HysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
