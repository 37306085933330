
    <div class="container">
        <div class="row pt-5 w-100">
           <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12" >
           <h2 class="header h1 h1-reponsive font-up font-bold mb-3 wow fadeInDown" data-wow-delay="0.3s"><strong>Our Services</strong></h2>
           <hr class="hr-strong mt-4 wow fadeInDown" data-wow-delay="0.4s">
           <p style="font-weight: bold;">
               Tratrust app is a mobile platform where you can access latest information and other services of the travel value chain at the comfort of your home.
            </p>
            </div>     
        </div>
    </div>

<!-- Services -->
<div class="container-fluid mb-4">
  <div class="row pt-2">
     <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12" >

     <div class="card-deck">
         <mdb-card class="wow fadeInUp">
           <mdb-card-body>
             <mdb-card-title>              
                 <div class="flex-row d-flex justify-content-center">
                 <i class="fas fa-passport fa-4x s-color"></i>
                 </div>
               <span class="head ">Automated Visa Processing</span>
             </mdb-card-title>
           </mdb-card-body>
         </mdb-card>
      
         <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="fas fa-plane-departure fa-4x s-color"></i>
                </div>
              <span class="head ">Flight Booking</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="fas fa-hotel fa-4x s-color"></i>
                </div>
              <span class="head ">Hotel Booking</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="far fa-file-code fa-4x s-color"></i>
                </div>
              <span class="head ">Software Development</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="fas fa-shopping-cart fa-4x s-color"></i>
                </div>
              <span class="head ">Luxury Window</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="fas fa-laptop-house fa-4x s-color"></i>
                </div>
              <span class="head ">House Arena</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                  <img src="assets/Ins.png" width="60" height="60"/>
                </div>
              <span class="head ">Travel Insuarance</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

        <mdb-card class="wow fadeInUp">
          <mdb-card-body>
            <mdb-card-title>              
                <div class="flex-row d-flex justify-content-center">
                <i class="fas fa-sitemap fa-4x s-color"></i>
                </div>
              <span class="head ">Organized Trips</span>
            </mdb-card-title>
          </mdb-card-body>
        </mdb-card>

       </div>
 </div>
</div>
</div>


<!-- Badge -->
<div class="row">
    <div class="col-12">
        <div class="badge-left px-3 pt-1 pb-2 wow fadeInLeft" >
            <span><i class="fas fa-users fa-2x white-text"></i></span> <span class="badge-text">What We Guarantee Users?</span>
        </div>
    </div>
</div>

<!-- Content -->
 <div class="container-fluid mb-4">
     <div class="row pt-2">
        <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12" >
   
        <div class="card-deck">
            <mdb-card class="wow fadeInUp">
              <mdb-card-body>
                <mdb-card-title>              
                    <div class="flex-row d-flex justify-content-center">
                    <i class="fas fa-user-clock fa-6x s-color"></i>
                    </div>
                  <span class="head ">Timeliness & Accountability</span>
                </mdb-card-title>
                <p class="b-description_readmore js-description_readmore text-justify">
                    Tratrust ensures a good turn around time with notifications at every stage of a process with absolute information, confidentiality and security of funds.    
                </p>
              </mdb-card-body>
            </mdb-card>
          
            <mdb-card class="wow fadeInDown">
              <mdb-card-body>
                <mdb-card-title>
                    <div class="flex-row d-flex justify-content-center">
                        <i class="fas fa-star-half-alt fa-6x s-color"></i>
                        </div>
                      <span class="head ">Rating System / Real Time Information</span>
                </mdb-card-title>
                <p class="b-description_readmore js-description_readmore text-justify">
                    Agents and Users will all be conscious of their conduct and access to real time information as it occur within the travel ecosystem. Both have incentive to behave, respect and be honest to the other party as both rates & ranks each other after a complete service cycle. Agents must maintain a high rating in order to attract more client.
    
                </p>
              </mdb-card-body>
            </mdb-card>
          
            <mdb-card class="wow fadeInUp">
              <mdb-card-body>
                <mdb-card-title>
                    <div class="flex-row d-flex justify-content-center">
                        <i class="fab fa-cc-amazon-pay fa-6x s-color"></i>
                        </div>
                      <span class="head ">Easy & Secure mode of payment / Dedicated travel consultant </span>
                </mdb-card-title>
                <p class="b-description_readmore js-description_readmore text-justify">
                    No exchange of cash between agents and users, therefore there’s no need to worry about scam or theft of funds during the process.    
                </p>
               </mdb-card-body>
            </mdb-card>

            <mdb-card class="wow fadeInDown">
                <mdb-card-body>
                  <mdb-card-title>
                    <div class="flex-row d-flex justify-content-center">
                        <i class="fas fa-search-dollar fa-6x s-color"></i>
                        </div>
                      <span class="head ">More Savings</span>
                  </mdb-card-title>
                  <p class="b-description_readmore js-description_readmore text-justify">
                    The best price travel content that comes with reduced stress of the overall search and book time with absolute convenience, trust and fulfilment. 
                          
                </p>
                </mdb-card-body>
              </mdb-card>

          </div>
    </div>
</div>
</div>

<!-- Badge -->
<div class="row">
    <div class="col-12">
        <div class="badge-right pl-5 pr-3 pt-2 pb-2 wow fadeInRight" >
            <span><i class="fas fa-user-tie fa-2x white-text"></i></span> <span class="badge-text">What We Guarantee Agents?</span>
        </div>
    </div>
</div>

<!-- Content -->
<div class="container-fluid mb-4">
    <div class="row pt-2">
       <div class="text-center col col-12 col-sm-12 col-md-12 col-lg-12" >
  
       <div class="card-deck wow fadeInLeft">
           <mdb-card>
             <mdb-card-body>
               <mdb-card-title>              
                   <div class="flex-row d-flex justify-content-center">
                   <i class="fas fa-shield-alt fa-6x s-color"></i>
                   </div>
                 <span class="head ">Instant Reward System & Guaranteed Safety</span>
               </mdb-card-title>
               <p class="b-description_readmore js-description_readmore text-justify">
                    Agents get their earnings/rewards instantly into their virtual accounts upon process initiation. Hence, a cashless transaction which  disincentive robbery and theft for agents is ensured.
                </p>
             </mdb-card-body>
           </mdb-card>
         
           <mdb-card>
             <mdb-card-body>
               <mdb-card-title>
                   <div class="flex-row d-flex justify-content-center">
                       <i class="fas fa-clipboard-list fa-6x s-color"></i>
                       </div>
                     <span class="head ">Choice</span>
               </mdb-card-title>
               <p class="b-description_readmore js-description_readmore text-justify">
                    Because of the rating system, agents will have access to what the agents’ community thinks of a user before they are linked up with them. This essentially weeds out "bad Users" much in the same way it weeds out bad agents. Tratrust will also ensure agents are compensated incase users misbehave.   
               </p>
             </mdb-card-body>
           </mdb-card>
         
           <mdb-card>
             <mdb-card-body>
               <mdb-card-title>
                   <div class="flex-row d-flex justify-content-center">
                       <i class="fab fa-readme fa-6x s-color"></i>
                       </div>
                     <span class="head ">Real-Time Information</span>
               </mdb-card-title>
               <p class="b-description_readmore js-description_readmore text-justify">
                    The Agents and users application will give real-time updates on the latest happenings in the industry globally. This will give the agents higher chances of finding clients (users), increase efficiency and maximize earning potential.
                </p>
              </mdb-card-body>
           </mdb-card>

           <mdb-card>
            <mdb-card-body>
              <mdb-card-title>
                <div class="flex-row d-flex justify-content-center">
                    <i class="fas fa-search-dollar fa-6x s-color"></i>
                    </div>
                  <span class="head ">Earn More with More Sign-Ups</span>
              </mdb-card-title>
              <p class="b-description_readmore js-description_readmore text-justify">
                Because of the earn more sign-up policy, agents are encouraged to sign-on during busy times. Hence, users have higher chances of finding an Agents at all times which drive new opportunity and ensure better earnings.      
            </p>
            </mdb-card-body>
          </mdb-card>

         </div>
   </div>
</div>
</div>
