<!--Main Navigation-->
<header class="intro-header" id="top">

  <!--Navbar-->
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
    <mdb-navbar-brand>
      <a routerLink="/" fragment="top" class="navbar-brand">
        <span class="title_header font-effect-shadow-multiple"><img class="animated bounceInDown" [src]="logo" width="50" height="40"/>
          <img class="img-logo" src="assets/t-logo.png"/>
        </span>
    </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" routerLink="" fragment="about"><mdb-icon fas icon="info-circle"></mdb-icon> About </a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/" fragment="services"><mdb-icon fas icon="laptop-house"></mdb-icon> Services</a>
          </li>
<!--           <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/" fragment="team"><mdb-icon fas icon="users"></mdb-icon> Team</a>
          </li> -->
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/" fragment="agent"><mdb-icon fas icon="user-tie"></mdb-icon> Agents</a>
          </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" routerLink="/" (click)="whyTratrust.show()"><mdb-icon fas icon="question-circle"></mdb-icon> Why Tratrust?</a>
        </li>
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" routerLink="/" fragment="footer"><mdb-icon fas icon="address-card"></mdb-icon> Contact</a>
        </li>

              <!-- Dropdown -->
<!--       <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light">
              <mdb-icon fas icon="user-circle"></mdb-icon> Account<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/" fragment="login"><mdb-icon fas icon="sign-in-alt"></mdb-icon> Sign-In/Sign-Up</a>
          </div>
        </li> -->
      </ul>

    </links>
  </mdb-navbar>

  <!-- Intro Section -->
  <div class="bg-view view jarallax" data-jarallax='{"speed": 0.2}'>
    <div class="full-bg-img mask rgba-black-light">
      <div class="container flex-center">
        <div class="row pt-5 mt-3 w-100">
          <div class="col-md-12">
            <div class="text-center">
              <h2 class="title_header h1 h1-reponsive white-text font-up font-bold mb-1 wow fadeInDown" data-wow-delay="0.3s">
                <strong class="header white-text">Tratrust</strong></h2>
              <hr class="hr-light mt-1 wow fadeInDown" data-wow-delay="0.4s">
              <h5 class="font-up mb-5 white-text wow fadeInDown" data-wow-delay="0.4s">
                <b>...an automated ecosystem of travel value chain</b></h5>
<!--               <a mdbBtn color="white" outline="true" class="wow fadeInDown waves-light" data-wow-delay="0.4s"
                mdbWavesEffect>portfolio</a>
              <a mdbBtn color="white" outline="true" class="wow fadeInDown waves-light" data-wow-delay="0.4s"
                mdbWavesEffect>About me</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<div mdbModal #whyTratrust="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="whyTratrust.hide()">
          <span aria-hidden="true" >×</span>
        </button>
        <h6 class="modal-title w-100" id="myModalLabel">Here's why you should use Tratrust technology and its Agents</h6>
      </div>
      <div class="modal-body">
        Tratrust agents will make travel less stressful with the aid of its technology.
When booking a vacation, destination wedding or honeymoon, the last thing a traveler should have to do is worry.

<p>
“It is a special memory in your lifetime that should only result in a pleasant experience all the way through tratrust technology and its agent. The tratrust agents are well prepared for your special memories as they take the worry off you and let you focus solely on the exciting events to come and what you shall pack.
</p>
<p><strong>Helps you save money…</strong>
Tratrust technology and its professionals Agents have access to special discount codes and promotions through suppliers. They often can save an additional amount or provide clients with a dining or spa credit that wouldn’t be available when booking through the tratrust mobile application.
</p>

<p><strong>…and time.</strong>
Nearly one-third of travelers in 2019, spent in excess of four hours, over multiple days, planning their own vacations. Tratrust technology create a custom itinerary, pulling together flights, transfers, accommodation, tours, visits, meals and exclusive visits based on your preferences—so you can spend your time on other things.
</p>

<p><strong>No charge hidden fees.</strong>
Tratrust Agents won’t charge fees outside the system at all, and those who do are up-front about the cost and none after the deal on the technology control mechanism.
</p>

<p><strong>They’ll always advocate on your behalf.</strong>
“Tratrust and the Agents will always save the family that has suffered a horrendous experience not only were they left with no refund but also a cancelled trip. Trying to scramble to plan something at the last minute is even worse.” Tratrust business propositions “timeliness, convenience, trust and fulfilment” got you covered 
</p>

<p><strong>Tratrust provide personalized attention via our dedicated Agent.</strong>
Tratrust provide a full-size-fits-all service, taking into account the numerous variables that are to be in play. Tratrust advisors (Agents) will tailor all services to clients’ individual needs. Have concerns? They’ll talk clients through them. Need a hotel that can cater to your allergies? A tratrust Agents advisor can speak to hotel managers to make sure it’s taken care of.
</p>

<p>
“Despite all the websites, clients by the truckload are desperate for a real human voice on the phone. They are clamoring for truly dedicated, genuine customer service and someone willing and eager to sell them what they want, which is tratrust business paraphernalia.  People readily buy from those ready to sell we believed in the principle of. Really taking care of our clients which will automatically take care of our profit margins.”
</p>

<p><strong>Travel insurance.</strong>
From guaranteeing your hotel is available to knowing if the excursion company is legitimate, booking through a travel agent ensures travelers will not get duped. And they provide insurance for medical emergencies and trip cancellations.
</p>

<p><strong>Tratrust will be 24/7.</strong>
Tratrust agents are on call 24 hours daily, seven days weekly. They’ll be there with you throughout the booking process and follow up afterward
</p>

<p><strong>Tratrust Agents are specialists.</strong>
Tratrust Agents have a personal understanding of your interests and insight into a specific destinations and segments of travel. Tratrust agent will spends a lot of time getting to know the customer and so can make recommendations tailored to what you want to do.
</p>

      </div>

    </div>
  </div>
</div>

</header>
<!--Main Navigation-->

