import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app-material.module';

import { HysComponent } from '../team-list/hys/hys.component';
import { AsdComponent } from '../team-list/asd/asd.component';
import { PsbComponent } from '../team-list/psb/psb.component';
import { AyComponent } from '../team-list/ay/ay.component';
import { MaComponent } from '../team-list/ma/ma.component';
import { SaComponent } from '../team-list/sa/sa.component';
import { DyiComponent } from '../team-list/dyi/dyi.component';
import { ZaComponent } from '../team-list/za/za.component';
import { AaComponent } from '../team-list/aa/aa.component';
import { HaComponent } from '../team-list/ha/ha.component';

@NgModule({
  declarations: [
    HysComponent,
    AsdComponent,
    PsbComponent,
    AyComponent,
    MaComponent,
    SaComponent,
    DyiComponent,
    ZaComponent,
    AaComponent,
    HaComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [
    HysComponent,
    AsdComponent,
    PsbComponent,
    AyComponent,
    MaComponent,
    SaComponent,
    DyiComponent,
    ZaComponent,
    AaComponent,
    HaComponent
  ],
})
export class TeamModule { }
