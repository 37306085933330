<app-navbar>/</app-navbar>


  <div class="main main-raised" id="about">  

<!--***************************************************************************************************************************  -->
<!-- Background Section -->
<section class="parallax" style="padding-top: 6vh; padding-bottom: 40px">

<app-background></app-background>


</section> <!-- End Background Section -->

<!--***************************************************************************************************************************  -->
<!-- Our services Section -->
<section class="" id="services" style="padding-bottom: 40px" >

  <app-our-services></app-our-services>

</section> <!-- End of Our services Section -->
   
   <!--***************************************************************************************************************************  -->

  <!-- Login Section -->
<!-- <section class="mng-parallax" id="team" style="padding-top: 0;">

<app-board></app-board>

</section> -->
<!-- End of Login Section -->
<!--***************************************************************************************************************************  -->

 <!-- Agents Card Section -->
 <section class="" id="agent" style="padding-bottom: 40px; margin-top: 3%;" >

  <app-agents-list></app-agents-list>

</section> <!-- End of Agent Section -->

<!--***************************************************************************************************************************  -->

<!-- Background Section -->
<section class="c-parallax" id="contact">

  <app-contact></app-contact>
  
  
  </section> <!-- End Background Section -->
  
  
</div><!--End main main-raised-->

<app-footer></app-footer> 