import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sa',
  templateUrl: './sa.component.html',
  styleUrls: ['./sa.component.scss']
})
export class SaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
