import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-flightbooking',
  templateUrl: './flightbooking.component.html',
  styleUrls: ['./flightbooking.component.scss']
})
export class FlightbookingComponent implements OnInit {

  multiCityForm: FormGroup;

  constructor(private fb:FormBuilder) { 
    this.multiCityForm = this.fb.group({
      name: '',
      flights: this.fb.array([]) ,
    });

  }

  flights() : FormArray {
    return this.multiCityForm.get("flights") as FormArray
  }
   
  newFlight(): FormGroup {
    return this.fb.group({
      depart: '',
      dest: '',
    })
  }
   
  addFlight() {
    this.flights().push(this.newFlight());
  }
   
  removeFlight(i:number) {
    this.flights().removeAt(i);
  }
   
  onSubmit() {
    console.log(this.multiCityForm.value);
  }

  ngOnInit(): void {
  }

}
