<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Dr. Salisu Aliyu (Board Member)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Salisu Aliyu,</strong> B.Sc. (ABU), M.Sc. (ABU), Ph.D. (ABU).Dr. Salisu Aliyu, a Lecturer at the Department of Computer Science, Ahmadu Bello University, Zaria. 
        He has a B.Sc. (Hons), M.Sc. and PhD. in Computer Science from the Ahmadu Bello University, Zaria. 
    </p>

    <P>
        He has a keen interest in software development and is a member of the University’s software development committee responsible for the development and deployment of software’s within and outside the University.
    </P>
    
    <p>
        Dr. Salisu Aliyu has more than a decade experience in academic teaching and research. 
        He specializes in programming, machine learning, and digital technologies, data analytics of complex networks and artificial intelligence.  
    </p>

</mat-dialog-content>