import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  title = 'TraTrust';
  logo = 'assets/logo2.png';
  
  constructor() { }

  ngOnInit() {
  }

}
