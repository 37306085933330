<div class="container">
<div class="card-deck wow fadeInDown">
    <!-- Card -->
    <mdb-card>
      <div class="view overlay waves-light pt-3" mdbWavesEffect>
        <!-- Card img -->
    <!--     <img src="../../assets/info.png" class="img-fluid z-depth-1 rounded-circle mx-auto d-block"
        alt="Responsive image"> -->
        <div class="flex-row d-flex justify-content-center">
            <i class="fas fa-info-circle fa-8x amv-color mx-auto"></i>
        </div>
                <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 class="header text-center">About Tratrust</h4>
        </mdb-card-title>
  
        <!--Text-->
        <p class="lead truncate"> 
          Tratrust is a disruptive all in one information sharing and on-demand mobile application, integrated with payment gateway.  Tratrust was developed to automate different processes as an ecosystem of the travel value chain. This innovation will  bring about a revolution in the business of public utility, travel & tour, transport and hospitality industries. Thousands of verified, registered and reliable Tratrust Agent with registered licensed and experienced service providers (Embassy’s Link) all on a single platform, available 24/7 to guarantee excellent service delivery at the comfort of your homes.

        </p>
        <button mat-button class="pull-right" (click)="openDialog()">Read more...</button>
      </mdb-card-body>
    </mdb-card>
  
    <!-- Card -->
    <mdb-card>
      <div class="view rgba-white-slight waves-light pt-3" mdbWavesEffect>
        <!-- Card img -->
        <div class="flex-row d-flex justify-content-center">
<!--         <img src="../../assets/vision2.jpg" class="img-fluid z-depth-1 rounded-circle mx-auto d-block"
        alt="Responsive image"> -->
        <i class="fas fa-binoculars fa-8x amv-color mx-auto"></i>
        </div>
                <a>
          <div class="mask"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 class="header text-center">Vision</h4>
        </mdb-card-title>
  
        <!--Text-->
        <p class="lead"> 
          To become the premier automated ecosystem of travel value chain with timeliness convenience and trust from the comfort of your home.
          </p>

  
      </mdb-card-body>
    </mdb-card>

    <!-- Card -->
    <mdb-card>
      <div class="view rgba-white-slight waves-light pt-3" mdbWavesEffect>
        <!-- Card img -->
<!--         <img src="../../assets/mission2.png" class="img-fluid z-depth-1 rounded-circle mx-auto d-block"
        alt="Responsive image"> -->
        <div class="flex-row d-flex justify-content-center">
            <i class="fas fa-route fa-8x amv-color mx-auto"></i>
        </div>
                <a>
          <div class="mask"></div>
        </a>
      </div>
      <!--Card content-->
      <mdb-card-body>
        <!--Title-->
        <mdb-card-title>
          <h4 class="header text-center">Mission</h4>
        </mdb-card-title>
  
        <!--Text-->
        <p class="lead"> 
          To be the biggest online mediators between stockholders of the travel value chain. Incorporate thousands of registered, verified, trained and certified Agent with professional service providers as an epitome of trust, timeliness, convenience and fulfilment.  

        </p>
      
      </mdb-card-body>
    </mdb-card>
  </div>
  </div>
