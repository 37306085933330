<!--Main Navigation-->

<div class=" jarallax pb-3" data-jarallax='{"speed": 0.2}' style="background-image: url('assets/bg/bg4.jpg'); padding-top: 12vh">
  <div class="full-bg-img mask rgba-black-light">
      <h3 class="text-center white-text font-bold"><strong class="bold">TRATRUST LIMITED PRIVACY POLICY</strong></h3>
    </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-justify p-3">

This Privacy Policy between the Tratrust Limited and you, constitutes our commitment to your privacy on our Information Technology records, websites, social media platforms and premises.
<strong class="bold">1.	Your Privacy Rights:</strong>
This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing and protection of your personal information. It applies to all the Tratrust website and all data base applications, services, tools and physical contact with us regardless of how you access or use them.
If you have created a username, identification code or password, at any time, if in our opinion you have failed to comply with any of the provisions of these conditions. If you know or suspect that anyone other than you know your security details, you must promptly notify us that at support@Tratrust.com or 09077208836

<strong class="bold">2.	Consent:</strong>
You accept this Privacy Policy when you give consent upon access to our platforms, or use our services, content, features, technologies or functions offered on the mobile technology, our website, digital platforms or visit our office for official or non-official purposes (Collectively Tratrust services). This Policy governs the use of Tratrust services and intervention projects by our users and Agents unless otherwise specifically agreed through written contract. We may amend this Privacy Policy at any time by posting a revised version on our mobile and website, or placing such notice at conspicuous points at our office facilities.

<strong class="bold">3.	Your Personal Information:</strong>
When you use Tratrust Services, we collect information sent to us by your computer, mobile phone or other electronic access device through the Platform. The automatically collected information includes but not limited to Biodata Information, address, computer IP address, , computer and connection information, mobile network information and other matters related therewith.

<strong class="bold">4.	What we do with your Personal Information:</strong>
The purpose of our collecting your personal information is to give you trusted, timeliness, convenient and secured fulfillment services. We may also use your information to:
•	Process applications and send notices about your transactions
•	Verify your identity
•	Manage risk, or to defect, prevent fraud or other potentially prohibited or illegal activities
•	Manage and protect our information technology and physical infrastructure;
•	Contact you at any time through your provided telephone number, email address or other contact details.


<strong class="bold">5.	Cookies:</strong>
Cookies are small files placed on your computer’s hard drive that enables the website to identify your computer as you view different pages. Cookies allow websites and applications to store your preferences in order to present contents, options or functions that are specific to you. Like most interactive websites, our website uses cookies to enable the tracking of your activity for the duration of a session. Our website uses only enabled session cookies which are erased either after the defined timeout period or once the user logs out of the platform and closes the browser. Session cookies do not collect information from the user’s computer. They will typically store information in the form of a session identification that does not personally identify the user.


<strong class="bold">6.	How we protect your personal information:</strong>
We store and process your personal information on our computers in Nigeria. Where we need to transfer your data to another country, such country must have an adequate data protection law. We will seek your consent where we need to send your data to a country without an adequate data protection law. We protect your information using a physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access (where it is only you that can access the mobile technology by using your own fingerprints, password or PIN to access it). Some of the safeguard we use are data encryption, information access authorization controls and means of security.

<strong class="bold">7.	Security:</strong>
We will always hold your information securely. To prevent unauthorized access to your mobile technology, we have implemented strong controls and security safeguards at the technical operational levels. This application uses a fingerprint, password or PIN access button, where it is only you as the user can access and make any monetary transaction in the application and also to ensure secure transmission of your personal data. You should see the symbol in your URL address bar once you are successfully logged into the platform. Any transaction during the session will be encrypted before transmission and decrypted when fingerprint, paaword or PIN has access it.
Tratrust has also taken measures to comply with Global Information Security Management Systems (ISMS) we therefore have put in place digital and physical security measures to limit or eliminate possibilities of data privacy been incidents.

<strong class="bold">8.	Data Confidentiality Rights:</strong>
Your information is regarded as confidential and will not be divulged to any third party except under legal and/or regulatory conditions. You have the right to request sight of, and copies of any and all information we keep on you, if such requests are made in compliance with the Freedom of Information Act and other relevant enactments. While Tratrust is responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes but not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with online security protocols on our mobile technology and website, dealing with only authorized Agencies.

<strong class="bold">9.	Links to other Websites and Platforms:</strong>
Certain transaction processing channels may require links to other websites or organisations other than ours. Please note that Tratrust is not responsible and has no control over websites outside its domain. We do not monitor or review the contents of other party’s websites which are linked from our website or media platforms. Opinion expressed or materials appearing on such websites are not necessarily shared or endorsed by us, Tratrust should not be regarded as the publisher of such opinions or materials. Please be aware we are not responsible for the privacy practices or content of these sites. We encourage our users to be aware of when they leave our site/platform and to read the privacy policy of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed through this site yourself, before disclosing any personal information to them. Tratrust will not accept any responsibility for any loss or damage in whatever manner, however caused, resulting from disclosure to third parties of personal information.


<strong class="bold">10.	Governing Law:</strong>
This Privacy Policy is made pursuant to the Nigeria Data Protection Regulation (2019) and other relevant Nigerian Laws, regulations or international conventions applicable to Nigeria. Where any provision of this Policy is deemed inconsistent with a law, regulation or convention, such provision shall be subject to the overriding law, regulation or convention.
 


   
  </div>
  </div>
  </div>
  