import { Component, OnInit } from '@angular/core';
import { AgentsService } from '../agents.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss']
})
export class AgentsListComponent implements OnInit {

  agents: any;

  constructor(private agentsService: AgentsService) { }

  ngOnInit() {
    this.getAgentsList();
  }

  /* Snaps the Firestore Database Data */
  getAgentsList() {
    this.agentsService.getAgentsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ agentReferenceNumber: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(agents => {
      this.agents = agents;
    });
  }

}
