<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mask rgba-black-strong waves-light d-flex align-items-center wow zoomIn" mdbWavesEffect style="min-height: 300px">
                      <iframe src="https://www.google.com/maps/embed/v1/directions?key=AIzaSyC6ngltY7YMz_lJnHU7DSytTPGZZLmwAOI&origin=Abuja,+Nigeria&destination=12+Sambrerio+Close,+Abuja,+Nigeria" width="100%" height="90%" frameborder="0" style="border:0; " allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> 
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 mask rgba-black-strong waves-light d-flex align-items-center" >

<mdb-card class="" style=" background: transparent !important;">
      <!--Card content-->
    <mdb-card-body>
  
      <!--Text-->
      <mdb-card-text> 
        <div class="row align-items-center mb-lg-5" >
            <div class="col-lg-1 col-md-1 col-sm-12  flex-row d-flex justify-content-center">
                <i class="fas fa-map-marked-alt fa-3x text-color "></i>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 c-text  ">
                No. 12, Sambrerio Crescent off Limpopo Street, Maitama Abuja.  
            </div>
        </div>
        <div class="row align-items-center mb-lg-5" >
            <div class="col-lg-1 col-md-1 col-sm-12  flex-row d-flex justify-content-center">
                <i class="fas fa-envelope fa-3x text-color"></i>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 c-text  ">
                info@tratrust.com<br/>
                support@tratrust.com<br/>
                report@tratrust.com
  
            </div>
        </div>
        <div class="row align-items-center mb-lg-5" >
            <div class="col-lg-1 col-md-1 col-sm-12  flex-row d-flex justify-content-center">
               <i class="fas fa-mobile-alt fa-3x text-color"></i>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-12 c-text  ">
                (+234) 9077208836 
            </div>
        </div>

      </mdb-card-text>
  
      <div class="flex-row d-flex justify-content-center mt-2">
        <a class="card-link" href="https://www.facebook.com/Tratrust-Tech-104424331486712/" target="_blank"><i class="fab fa-facebook-square fa-2x text-color"></i></a>
        <a class="card-link" href="https://www.instagram.com/tratrusttech/" target="_blank"><i class="fab fa-instagram fa-2x text-color"></i></a>
        <a class="card-link" href="https://twitter.com/tratrusttech" target="_blank"><i class="fab fa-twitter-square fa-2x text-color"></i></a>
        <a class="card-link" href="https://www.youtube.com/channel/UCfbKUx479fLXdx69BTuGy5w" target="_blank"><i class="fab fa-youtube fa-2x text-color"></i></a>
      </div>

    </mdb-card-body>
  </mdb-card>

  </div>
  </div>
  </div>