import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import { HysComponent } from './team-list/hys/hys.component';
import { AsdComponent } from './team-list/asd/asd.component';
import { PsbComponent } from './team-list/psb/psb.component';
import { AyComponent } from './team-list/ay/ay.component';
import { MaComponent } from './team-list/ma/ma.component';
import { SaComponent } from './team-list/sa/sa.component';
import { DyiComponent } from './team-list/dyi/dyi.component';
import { ZaComponent } from './team-list/za/za.component';
import { AaComponent } from './team-list/aa/aa.component';
import { HaComponent } from './team-list/ha/ha.component';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  constructor(public dialog: MatDialog) {}


    openHys(): void {
      const dialogRef = this.dialog.open(HysComponent, {
        width: '400px',
      });
    }

    openAsd(): void {
      const dialogRef = this.dialog.open(AsdComponent, {
        width: '400px',
      });
    }

    openPsb(): void {
      const dialogRef = this.dialog.open(PsbComponent, {
        width: '400px',
      });
    }

    openAy(): void {
      const dialogRef = this.dialog.open(AyComponent, {
        width: '400px',
      });
    }

    openMa(): void {
      const dialogRef = this.dialog.open(MaComponent, {
        width: '400px',
      });
    }

    openSa(): void {
      const dialogRef = this.dialog.open(SaComponent, {
        width: '400px',
      });
    }

    openDyi(): void {
      const dialogRef = this.dialog.open(DyiComponent, {
        width: '400px',
      });
    }

    openZa(): void {
      const dialogRef = this.dialog.open(ZaComponent, {
        width: '400px',
      });
    }

    openAa(): void {
      const dialogRef = this.dialog.open(AaComponent, {
        width: '400px',
      });
    }

    openHa(): void {
      const dialogRef = this.dialog.open(HaComponent, {
        width: '400px',
      });
    }

  ngOnInit(): void {
  }

}
