import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { BgMoreComponent } from "../background/bg-more/bg-more.component";
/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {

  constructor(public dialog: MatDialog) {}


    openDialog(): void {
      const dialogRef = this.dialog.open(BgMoreComponent, {
        width: '70%',

      });
  }

  ngOnInit(): void {
  }

}
