<footer class="footer" id="footer" data-background-color="black">
        <div class="container wow shake">
          <nav class="float-left">
            <ul>
              <li><a routerLink="/" fragment="top"><img class="animated bounceInDown" src="assets/logo.png" width="50" height="30"/></a></li>
              <li>
                <a routerLink="/" fragment="about">
                  About Us
                </a>
              </li>
              <li>
                <a routerLink="/" fragment="services">
                  Services
                </a>
              </li>
<!--               <li>
                <a routerLink="/" fragment="team">
                  Team
                </a>
              </li> -->
              <li>
                <a routerLink="/" fragment="agent">
                  Agent
                </a>
              </li>
              <li>
                <a [routerLink]="['/privacyPolicy']" >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a [routerLink]="['/terms&condition']">
                  Terms & Conditions
                </a>
              </li>

<!--               <li class="nav-item">
                    <a class="nav-link" rel="tooltip" title="" data-placement="top" fragment="" data-original-title="Follow us on Twitter">
                        <mdb-icon fab icon="twitter-square"></mdb-icon>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" rel="tooltip" title="" data-placement="bottom" fragment="" data-original-title="Like us on Facebook">
                        <mdb-icon fab icon="facebook-square"></mdb-icon>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" rel="tooltip" title="" data-placement="bottom" href="https://www.instagram.com/" target="_blank" data-original-title="Follow us on Instagram">
                        <mdb-icon fab icon="instagram"></mdb-icon>
                    </a>
                  </li> -->
            </ul>
          </nav>
          <div class="copyright float-right">
            &copy; {{currentDate | date:'yyyy'}} Copyright
            <a routerLink="/" fragment="top">Tratrust.</a> All Rights Reserved..
          </div>
        </div>
      </footer> 