<!--Main Navigation-->

  <div class=" jarallax pb-3" data-jarallax='{"speed": 0.2}' style="background-image: url('assets/bg/bg4.jpg'); padding-top: 12vh">
<div class="full-bg-img mask rgba-black-light">
    <h3 class="text-center white-text font-bold"><strong class="bold">TRATRUST LIMITED TERMS & CONDITIONS</strong></h3>
  </div>
  </div>
  <div class="container">
      <div class="row">
          <div class="col-lg-12 text-justify p-3">
 
  <strong class="bold">TRATRUST IS AN INFORMATION SHARING AND ON-DEMAND WEB AND MOBILE APPLICATION, DEVELOPED BY TRATRUST LIMITED</strong>
  Automation of the Ecosystem of the travel value chain, as “An epitome of trust, convenience, timeliness and fulfilment” which allows registered, users of the platform to access and interact with Tratrust’s Agents, (service providers) and other users on the platform for the purpose of service request/provision (business interactions), safety, rescue, fun and fulfilment.

<p>
  Tratrust limited retains the ownership of Tratrust applications (platform), designs, logo to the exclusion of any other. Tratrust trademarks, service marks, logos, domain names, and any other features of the platform are the sole property of Tratrust limited. Business Connections and its associated. Usage of the platform does not grant you any right(s) to use the Tratrust trademarks, service marks, trade names, logos, domain names, or any other features of the Tratrust, whether for commercial or non-commercial use.
</p>
<p>
  You agree to abide by the Terms and condition and not to use the platform (including but not limited to its content) in any manner not expressly permitted by the terms or involve in acts or omissions contrary to the governing laws our land, the federal republic of Nigeria.
</p>
 <p style="font-weight: bold;"> 
  ACCESSING OR OTHERWISE USING THE MOBILE APPLICATION AND SITE, INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING
</p>
<p>
  Tratrust reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the platform and Site, following the posting of changes will mean that you accept and agree to the changes. If you comply with the Terms of the platform, Tratrust grants you a personal, non-exclusive, non-transferable, limited privilege to access and use the platform.
</p>
  <h6 class="color-text"><strong class="bold"> Users Eligibility</strong></h6>
  <p>
    Platform users need to download the application and register to have access to the platforms and enjoy the epitome of trust, timeliness, convenience and fulfillment of the platforms.  
    </p>
  <h6 class="color-text"><strong class="bold"> ACCOUNT AND REGISTRATION OBLIGATIONS FOR USERS/AGENTS</strong></h6>
  <p>
    The user/Agents agrees to provide only accurate and current information of himself.
    </p>
<p>
  Tratrust shall maintain the confidentiality of Agents ID, password and any other credential and documents submitted to be confidential as may be required by the modalities of using Tratrust application (platform).
</p>
<p>
  Tratrust shall not disclose the confidential information provided by the Agent to any other party except its affiliates, and or to other relevant authority for security purpose on request. Certain information obtained like usernames, Agent number, ratings, displayed pictures will be displayed to the public.
</p>

  <h6 class="color-text"><strong class="bold">Use of Tratrust Platform</strong></h6>
<p>
  Use of Tratrust Platform YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU SEND OR POST ON THE platform.
</p>
<!-- <h6 class="color-text"><strong class="bold">Privacy & Policy</strong></h6>
<p>
  Tratrust is committed to protecting the privacy of all Agents and users. Collection of Personal Information “Personal Information” means information from which your identity is apparent or can be reasonably ascertained. The types of information generally collected by us include your name, and means of identity email address amongst others. By providing us with your Personal Information, you consent to the practices described in this Privacy Policy. By registering on this Platform, you agree and where required you consent to the collection, use and transfer of your information as set out in this policy. The Data collected is protected in compliance with the Nigerian Data Protection Regulation.
</p> -->
<h6 class="color-text"><strong class="bold">Use of your information</strong></h6>
<p>
  Your information will enable us to provide you with access to the relevant parts of the Platform, It will also enable us to contact you when necessary concerning the platform. We will use and analyze the information we collect so that we can administer, support, improve and develop the platform for any other purpose whether statistical or analytical and to help us prevent fraud. We may use your information to contact you for your views on Tratrust and to notify you occasionally about important changes or developments to the Platform. Where you have indicated accordingly, you agree that we may use your information to let you know about our other products that may be of interest to you including Tratrust that may be the subject of direct marketing and we may contact you to do so by post, telephone, mobile messaging (e.g. SMS, MMS etc.) as well as by e-mail.
</p>
<p>
The use of your Personal Information may include the following:
</p>
  <ul>
  <li> To enable access to the platform.</li>
    <li>For identification purpose.</li>
    <li>For direct marketing purposes.</li>
    <li>For pairing of agent to user.</li>
  </ul>

  Your Personal Information may be added to our database for ongoing marketing and educational purposes. The type of marketing and educational activities that we undertake includes forwarding you various material to keep you updated about our platform and other third party issues which may come to our attention. If at any time, you do not wish to continue receiving this information, we provide an “opt-out” procedure in each communication to you. Please note that by submitting comments and feedback regarding the Platform, you consent to us to use such comments and feedback on the Platform and in any marketing or advertising materials. We will only identify you for this purpose by your username. Some of the advertisements you see on the Platform are selected and delivered by third parties, such as ad networks, advertising agencies, advertisers, and audience segment providers. These third parties may collect information about you and your online activities, either on the Platform, through cookies, web beacons, and other technologies in an effort, to understand your interests and deliver you advertisements that are tailored to your interests. Please remember that we do not have access to, or control over, the information these third parties may collect. The information practices of these third parties are not covered by this privacy policy.
  <h6 class="color-text"><strong class="bold">Disclosure of your information</strong></h6>
  The information you provide to us will be transferred to and stored on our servers. By submitting your personal data, you agree to this transfer, storing or processing. Tratrust will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy. Third parties process information such as card payments for the wallet. It is worthy of note that al payment online is done by third party.

  <h6 class="color-text"><strong>WITHDRAWAL OF CONSENT</strong></h6>
  If you send or submit to us any of your personal data and information, we will take it to mean that you have given us the consent to utilize your data solely for what we require it for in meeting your needs, requests, and complaints. You also have the right at any time to withdraw your consent but the withdrawal of this consent will not affect the lawfulness of any prior processing we carried out.
  

  <h6 class="color-text"><strong>UPDATES TO THIS POLICY</strong></h6>
  From time to time, TRATRUST LIMITED may change or update this Policy in order to comply with applicable laws and regulations or following a change in our internal policies and practices. We will at all times notify you of any change or update.


    <h6 class="color-text"><strong class="bold">Security and data retention</strong></h6>
We take steps to protect your information from unauthorized access and against unlawful processing, your user password allows you to access to the Platform, you are responsible for keeping this password confidential. We advise you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Platform; any transmission is at your own risk. Tratrust will not be held responsible for any or all damage occurring as result of this. By using our Tratrust platform and providing Personal Information, you are accepting these risks and hereby indemnifying Tratrust for any/all liability arising from the use of the Tratrust. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access. Data collected is in compliance with the Nigerian Data Protection Regulation, 2019. Tratrust

<h6 class="color-text"><strong class="bold">FEATURES OF THE PLATFORM</strong></h6>
    <ul>
      <li> To enable access to the platform.</li>
      <li>Visa processing</li>
      <li>Visa Queue depository</li>
      <li>Visa tracking system</li>
      <li>Instant Messaging</li>
      <li>Flight booking</li>
      <li>Hotels booking</li>
      <li>Audio and Video call</li>
      <li>Wallet</li>
      <li>Trends</li>
      <li>Verified Business profiles</li>
      <li>Travel insurance</li>
      <li>Luxury Window</li>
    </ul>
<h6 class="color-text"><strong class="bold">WALLET</strong></h6>    
Users make payment to the user account profiles through the wallet feature of the platform via online transaction, online transactions for recharging of the wallet and or transfer of money from the wallet is done by a third party payment gateway (PAYSTACK) or any other payment gateway.

<h6 class="color-text"><strong class="bold">DISCLAIMER</strong></h6>
<p>
Tratrust will not be liable to any extent whatsoever for any loss, inadequacy or damages that resulted from any transaction in the course of using the payment gateway and or the platform.
</p>
<p>
  Tratrust does not have control over the payment gateway or the issuing bank of the user. In a situation where the user has any complaint from the use of the payment gateway. The user should contact us via info@tratrust.com for solution.
</p>
<p>
  No exchange of cash whatsoever between user and Agents is allowed which means every transactions that required any form of payments by the user must done by the user directly through the platform by himself. For any reason whatsoever a user decide to give agents or third party his money outside the platform, he is doing so at his own risk.
</p>
<p>
  Tratrust Limited grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and non-sub licensable license to access and use the Services. This license is for the sole purpose of letting you use and enjoy the Service’s benefits in a way that these Terms and our usage policies.
</p>
<p>
  Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device’s settings.
</p>
<p>
  You may not copy, modify, distribute, sell, or lease any part of our Services, nor may you reverse engineer or attempt to extract the source code of that software, unless applicable laws prohibit these restrictions or you have our written permission to do so.
</p>
<p>
  Some part of our Services let you upload, make and receive calls, chat including send, receive documents and store content. When you send any documents through Tratrust, you are solely responsible for your content. But you grant us a license to use that content. How broad that license is depends on which Services you use and the interface or section you have selected.
</p>
<p>
  You grant Tratrust a worldwide, royalty-free, sub licensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute that content. This license is for the limited purpose of operating, developing, providing, promoting, and improving the Services and researching and developing new ones.
</p>
<p>
  Because Live, Local, and any other crowd-sourced Services are inherently public and chronicle matters of public interest, the license you grant us for content submitted to those Services is broader. In addition to the rights you grant us in connection with other Services, you also grant us a perpetual license to create derivative works from, promote, exhibit, broadcast, syndicate, publicly
</p>
<p>
  Much of the content on our Services is supplied by users, agents, service providers, and other third parties. Whether that content is used publicly or sent privately, the content is the sole responsibility of the person or organization that submitted it. Although Tratrust reserves the right to review all content that appears on the Services and to remove any content that violates these Terms, we do not necessarily review all of it. Tratrust SHALL NOT take responsibility for any content that others provide through the Services.
</p>
<p>
  To the extent it’s necessary, you also grant Tratrust and our business partners the unrestricted, worldwide, perpetual right and license to use your name, pictures, and voice solely in Live, Local, or other crowd-sourced content that you appear in, create, upload, send to wherever necessary. This means, among other things, that you will not be entitled to any compensation from Tratrust or our business partners if your name, picture and voice is conveyed through Live, Local, or other crowd-sourced Services.
</p>
<p>
  The Services may contain advertisements. In consideration for Tratrust letting you access and use the Services, you agree that Tratrust, its affiliates, may place advertising on the Services.
</p>
<p>
  Tratrust respects the rights of others, same is expected of every user, agent and service providers. You therefore may not:
</p>
<ul>	
        <li>violates or infringes someone else’s rights of transaction and information sources,</li> 
        <li>bullies, harasses, or intimidates;</li>
        <li>defames; or</li>
        <li>Spams or solicits Tratrust ‘s users agents or service providers.</li>
    </ul>

    You must also respect Tratrust’s rights. These Terms do not grant you any right to:
    <ul>
        <li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms;</li>
        <li>use branding, logos, designs, photographs, videos, or any other materials used in our Services;</li>
    </ul>

  <h6 class="color-text"><strong class="bold">GOVERNING LAW AND JURISDICTION</strong></h6>
  
  This Agreement shall be interpreted in accordance with the laws of the Federal Republic of Nigeria. Any dispute arising from or in connection with this Agreement, including any issues concerning responsibility, liability or limitations of liability, shall be determined in accordance with the Arbitration Act of the Federal Republic of Nigeria.

<p><strong class="bold">Restrictions</strong></p>
You may not:
  <ol>
  <li> 
      Use Tratrust for any illegal purpose.
  </li>

  <li> 
    reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Tratrust platform except as expressly permitted by Tratrust;
  </li>

  <li>
    decompile, reverse engineer or disassemble the Tratrust except as may be permitted by applicable law;
  </li>

  <li> 
    remove any copyright, trademark or other proprietary notices from any portion of the Tratrust
  </li>

  <li> 
    link to, mirror or frame any portion of the Tratrust
  </li>

  <li>
    cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Tratrust platform or unduly burdening or hindering the operation and/or functionality of any aspect of the Tratrust; or
  </li>

  <li>
    Attempt to gain unauthorized access to or impair any aspect of the Tratrust or its related systems or networks.
  </li>
</ol>

  <p><strong class="bold">Text Messaging, voice or video Calls</strong></p>
    <p>
      You agree that Tratrust may contact you by telephone or text messages (including by an automatic telephone dialing system) at any of the phone numbers provided by you or on your behalf in connection with a Tratrust account, including for marketing purposes. You understand that you are not required to provide this consent as a condition of purchasing any property, goods or Tratrust.
    </p>

<p><strong class="bold">Network Access and Devices</strong></p>
    <p>
      You are responsible for obtaining the data network access necessary to use the Tratrust. Your mobile network’s data and messaging rates and fees may apply if you access or use the Tratrust from your device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Tratrust and Applications and any updates thereto. Tratrust does not guarantee that the Tratrust, or any portion thereof, will function on any hardware or devices. In addition, the Tratrust may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.
    </p>
<p><strong class="bold">Charges</strong></p>
    <p>
      You understand that use of the Tratrust we may result in charges to you for the purchase of products Tratrust or goods you receive (“Charges”). Tratrust will receive and/or enable your payment of the applicable Charges for Tratrust or goods obtained through your use of the Tratrust Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges
    </p>

<h6 class="color-text"><strong class="bold">Disclaimer of Warranties</strong></h6>
    
You expressly agree that your use of the platform is at your sole risk. To the full extent permissible by law, Tratrust disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Tratrust does not warrant that the services, information, content, materials, products (including software) or other services included on or otherwise made available to you through the platform, Tratrust ‘s servers or electronic communications sent from Tratrust is free of viruses or other harmful components. To the full extent permissible by law, Tratrust will not be liable for any damages of any kind arising from the use of the platform or from any information, content, materials, products (including software) or other services included on or otherwise made available to you through Tratrust, including, but not limited to direct, indirect, incidental, punitive, and consequential damages. Your use of the platform, its content and any tratrust material obtained through the tratrust is at your own risk. The platform, its content and any tratrust obtained through the platform are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. Neither Tratrust does not makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy or availability of the service. As such tratrust limited shall not be held responsible for the act(s) or omission of users or service professionals under the tratrust platforms.

<h6 class="color-text"><strong class="bold">Limitation on Liability</strong></h6>
    
IN NO EVENT WILL TRATRUST, ITS AFFILIATES OR THEIR LICENSORS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE TRATRUST PLATFORMS, ANY TRATRUST S LINKED TO IT, ANY  TRATRUST CONTENT ON THE TRATRUST OR SUCH OTHER TRATRUST S  AFFILIATES OR ANY TRATRUST OR ITEMS OBTAINED THROUGH THE TRATRUST , INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. Services rendered by Seller pursuant to this Agreement are fully and entirely Seller’s responsibility solely. TRATRUST SHALL not be responsible or liable for the actions or inactions of users or other third party in relation to the Services provided through the use of the platform therefore, that by using the Tratrust, Service requester uses the Tratrust at his/her own risk and as such Tratrust shall not be sued in respect to liabilities, actions and in actions of the service professional. Tratrust MAY provide information available based on the information provided to the platform by the user only at the request of the police, other law enforcement agency or authority that has such power. As such shall not be partially or and in its totality be liable for any and or all acts or and omission of the user.

<h6 class="color-text"><strong class="bold">Force Majeure</strong></h6>
    
Under no circumstances shall Tratrust and our affiliates agents and service providers be held liable for any delay or failure or disruption of the Transaction Services resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, acts of God, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labour disputes, pandemics, riots, insurrections, civil disturbances, shortages of labor or materials, terrorism, war, governmental actions, orders of domestic or foreign courts or tribunals.

<h6 class="color-text"><strong class="bold">Notices</strong></h6>     
Notices Except as explicitly stated otherwise, legal notices shall be served on by sending notices to the email address in your latest membership profile on the Sites. Notice shall be deemed given 24 hours after email is sent, unless we are notified that the email address is invalid. Alternatively, we may give you legal notices by mail to the address in your latest membership profile in which case the notice shall be deemed given 24 hours after the date of mailing. Except as explicitly stated otherwise, legal notices shall be served on Tratrust at her registered office address.

<h6 class="color-text"><strong class="bold">DISPUTE RESOLUTION</strong></h6>
    
Disputes between USER, AGENTS and SERVICE PROVIDER. You agree that any Dispute arising between you and the other party to an Online Transaction will be handled in accordance with clause 10, and that Tratrust shall have the full right and power to make a determination for such Dispute or to delegate or sub-contract such power to another party. Upon receipt of a Dispute, Tratrust shall have the right to request either or both of USER, AGENTS and SERVICE PROVIDER to provide supporting documents. You agree that Tratrust shall have the absolute discretion to reject or receive any supporting document. You also acknowledge that Tratrust is not a judicial or arbitration institution and will make the determinations only as an ordinary non-professional person. Further, we do not warrant that the supporting documents that the parties to the Dispute submit will be true, complete or accurate. You agree not to hold Tratrust and our affiliates liable for any material which is untrue or misleading. You agree to release and indemnify Tratrust (and our agents, affiliates, directors, officers and employees) from all claims, demands, actions, proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or consequential damages) arising out of or in connection with such dispute.

<h6 class="color-text"><strong class="bold">DISPUTE BETWEEN TRATRUST AND USERS/AGENTS</strong></h6>    
    
In case a Dispute arises between you and Tratrust, if the Dispute is not resolved between you and Tratrust by amicable negotiations and except as otherwise stipulated under applicable law, you and Tratrust agree that the Dispute shall be finally resolved by arbitration. Any dispute against Tratrust must be brought to the notice of Tratrust within 30 days from the date of the dispute or else the dispute shall be deemed abandoned and unenforceable.

<h6 class="color-text"><strong class="bold">THE FEES FOR ARBITRATION SHALL NOT BE BORE BY TRATRUST</strong></h6>    
    
BY CHECKING THE BOX STATING I AGREE TO THE SERVICE PROFESSIONAL AGREEMENT, YOU HEREBY AGREE TO THIS SERVICE PROFESSIONAL AGREEMENT. You understand that your electronic signature is as legally binding as a handwritten signature.    
</div>
</div>
</div>
