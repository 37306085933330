<!-- Rating Stars -->
<p>
  <!-- <strong *ngIf="agent.agentRating != '0.0' || null ">{{agent.agentRating}}</strong> -->

  <span id="rateMe" *ngIf="agent.agentRating === '0.0' || agent.agentRating === null">
    <i class="far fa-star py-2 px-1" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="far fa-star py-2 px-1" data-index="1" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Poor"></i>
    <i class="far fa-star py-2 px-1" data-index="2" data-html="true" data-toggle="tooltip" data-placement="top"
      title="OK"></i>
    <i class="far fa-star py-2 px-1" data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1" data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      data-original-title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '1.0'">
    <i class="fas fa-star py-2 px-1 oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="far fa-star py-2 px-1" data-index="1" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Poor"></i>
    <i class="far fa-star py-2 px-1" data-index="2" data-html="true" data-toggle="tooltip" data-placement="top"
      title="OK"></i>
    <i class="far fa-star py-2 px-1" data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1" data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '1.5' || agent.agentRating === '1.25'">
    <i class="fas fa-star py-2 px-1 oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star-half-alt py-2 px-1 twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="far fa-star py-2 px-1" data-index="2" data-html="true" data-toggle="tooltip" data-placement="top"
      title="OK"></i>
    <i class="far fa-star py-2 px-1" data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1" data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '2.0'">
    <i class="fas fa-star py-2 px-1 oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1 twoStars" data-index="1" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Poor"></i>
    <i class="far fa-star py-2 px-1" data-index="2" data-html="true" data-toggle="tooltip" data-placement="top"
      title="OK"></i>
    <i class="far fa-star py-2 px-1" data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1" data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '2.5'">
    <i class="fas fa-star py-2 px-1  oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1  twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="fas fa-star-half-alt py-2 px-1  threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="far fa-star py-2 px-1  " data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1  " data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '3.0'">
    <i class="fas fa-star py-2 px-1  oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1  twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="fas fa-star py-2 px-1  threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="far fa-star py-2 px-1  " data-index="3" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Good"></i>
    <i class="far fa-star py-2 px-1  " data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '3.5'">
    <i class="fas fa-star py-2 px-1  oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1  twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="fas fa-star py-2 px-1  threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="fas fa-star-half-alt py-2 px-1  fourStars" data-index="3" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Good"></i>
    <i class="far fa-star py-2 px-1  " data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '3.75' || agent.agentRating === '4.0'">
    <i class="fas fa-star py-2 px-1  oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1  twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="fas fa-star py-2 px-1  threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="fas fa-star py-2 px-1  fourStars" data-index="3" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Good"></i>
    <i class="far fa-star py-2 px-1 " data-index="4" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '4.5'">
    <i class="fas fa-star py-2 px-1 oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1 twoStars" data-index="1" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Poor"></i>
    <i class="fas fa-star py-2 px-1 threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="fas fa-star py-2 px-1 fourStars" data-index="3" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Good"></i>
    <i class="fas fa-star-half-alt py-2 px-1 fiveStars" data-index="4" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Excellent"></i>
  </span>

  <span id="rateMe" *ngIf="agent.agentRating === '5.0'">
    <i class="fas fa-star py-2 px-1  oneStar" data-index="0" data-html="true" data-toggle="tooltip" data-placement="top"
      title="Very bad"></i>
    <i class="fas fa-star py-2 px-1  twoStars" data-index="1" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Poor"></i>
    <i class="fas fa-star py-2 px-1  threeStars" data-index="2" data-html="true" data-toggle="tooltip"
      data-placement="top" title="OK"></i>
    <i class="fas fa-star py-2 px-1  fourStars" data-index="3" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Good"></i>
    <i class="fas fa-star py-2 px-1  fiveStars" data-index="4" data-html="true" data-toggle="tooltip"
      data-placement="top" title="Excellent"></i>
  </span>

</p>
<!-- End of Rating Stars -->