<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Professor Sahalu B.Jumaid <br/>(Board Member)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Professor Sahalu B. Junaidu,</strong> Director Institute of Computing and ICT, Ahmadu Bello University Zaria, 
        Professor Sahalu B. Junaidu is a renowned Professor globetrotting the world for his vast knowledge and experience in the ICT World. 
    </p>

    <p>
        He Holds a Ph.D.  In Computer Science (St Andrews University Scotland), M.Sc. 
        Advanced Methods in Computer Science (Formal Methods in Software Engineering), 
        Queen Mary and Westfield College University London. B.Sc. Mathematics and Computer Science (ABU) Zaria, 
        Diploma Mathematics Education (ABU) Zaria. 
    </p>
    
    <p>
        He has a special interest in parallel computing, web application developments, programming language and e-learning. 
    </p>

</mat-dialog-content>