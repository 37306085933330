<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd">Ahmed Yusuf (Board Member)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Ahmed Yusuf,</strong> A renowned entrepreneur is the founder and CEO of Northwest Intercontinental Concepts (One-Stop Auto).
    </p>

    <p>
        He graduated with B.Sc. (Hons) Geography from the Ahamdu Bello University, Zaria and M.Sc. 
        Transport Management from the same University. He also bagged a Post Graduate Diploma in remote sensing and 
        masters in Disaster risk and management studies from same university.
    </p>

    <p>
        Ahmed Yusuf is also a certified project management professional (PMP), a member of Institute of Information 
        and Strategy Management (IISM), and many other associations.
    </p>

    <p>
        He is a seasoned banker with over a decade experience in retail banking and agricultural value chain, 
        with vast experience in sales, marketing and evaluation of products.
    </p>

    <p>
        His experience in entrepreneurship over the years and his enthusiastic engagement  in the Transport and 
        Fin-tech space cannot be over emphasized.
    </p>

</mat-dialog-content>