// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//Live environment
export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyDAhEblTvqJD9K3_6eTc7gSicqgjx2dp0w",
    authDomain: "tratrust-limited.firebaseapp.com",
    projectId: "tratrust-limited",
    storageBucket: "tratrust-limited.appspot.com",
    messagingSenderId: "620756865823",
    appId: "1:620756865823:web:ff1d3cffa0ce0b138c962a",
    measurementId: "G-WS8D8Q02E8"
  }
};

//Test environment
/* export const environment = {
  production: true,
   firebaseConfig : {
    apiKey: "AIzaSyAsirs9-_NaEHTd_XVHobAavpw0CgNSPJs",
    authDomain: "tratrust-c017f.firebaseapp.com",
    databaseURL: "https://tratrust-c017f.firebaseio.com",
    projectId: "tratrust-c017f",
    storageBucket: "tratrust-c017f.appspot.com",
    messagingSenderId: "540918756332",
    appId: "1:540918756332:web:a0da16b2dc3011369ec6c9",
    measurementId: "G-62ERYXL051"
  }
}; */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
