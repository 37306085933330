import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Agents } from './agents.model';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  private dbPath = '/AGENTS';

  AgentsRef: AngularFirestoreCollection<Agents>;

  constructor(public db: AngularFirestore) {
    this.AgentsRef = db.collection(this.dbPath, ref => ref.orderBy('agentRating', 'asc').where('documentationStatus', '==', 'DOCUMENTED').limit(12));
  }

  getAgentsList(): AngularFirestoreCollection<Agents> {
    return this.AgentsRef;
  }
  
}
