<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="header">Our Background</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn ">
    <div class="mx-3">
    <p>
        <strong>Tratrust</strong> is a disruptive all in one information sharing and on-demand mobile application, integrated with payment gateway.  
        Tratrust was developed to automate different processes as... <strong>an ecosystem of the travel value chain.</strong> 
        This innovation will  bring about a revolution in the business of public utility, travel & tour, transport and hospitality industries. 
        Thousands of verified, registered and reliable Tratrust Agent with registered licensed and experienced service providers (Embassy’s Link) all on a single platform, available 24/7 to guarantee excellent service delivery at the comfort of your homes.
    </p>

    <p>
        <strong>Tratrust</strong> offers a new innovative approach to Visa processing. Unlike in the past where Visa applicants seeking agents to get professional advice and services, accept only for word of mouth verification. 
        Tratrust has a number of verified visa consultants, ticketing agencies, educational consultants and foreign recruiters for Nigerian staff. 
        In addition, it provides opportunity for new applicants to pick from the registered agents to the required services at the comfort of their homes. 
        Tratrust protects the client and protects the service providers as well. Tratrust mediates between clients and service providers, such that clients deposit service charges into their virtual accounts and the service providers get paid by the system. 
        If the agent is unable to deliver within the timeline,  the client gets refunded back into their virtual account. This is to ensure transparency and accountability.
    </p>

    <p>
        In the <strong>21st century,</strong> the global economy is driven by major industries which include technology, 
        telecommunication (cyber and software), travel and tour, transport and hospitality industries. 
        Nowadays, travel & tour, transport and hospitality industries are the jet engines in most country’s economy. Hence, opening new socio-economic growth and development. 
    </p>

    <p>
        Nigeria is home to various tourist sites and destinations owing to its landscapes, indigenous culture, and history with enormous business opportunities. 
        The travel & tour industry accounts for about 34% of it’s GDP and about 20% of the nation’s employment.  
        According to the latest update by the International Air Transport Associations (IATA), 20-years air passengers forecast shows that an increasing shift eastward in the center of gravity of the industry is behind the continued strong growth. 
        It revealed that the number of passengers is going to grow significantly to 8.2 billion in the year 2037 as against 3.6billion international travelers in the year 2016. 
        The above shows that the travel industry is currently the fastest growing and the biggest industry at the moment even bigger than pharmaceutical, entertainment, and the oil and gas industry. 
        The majority of the growth will come from Asia and African, with Nigeria having the lion share, because Nigeria's population is going to increase significantly and more than half of the population would want to travel internationally for knowledge, asylum seeking, adventure, health, sport and other endeavors. 
        The world travel and tourism councils and world travel organization research revealed that the sector accounted for 10.4% of the global GDP and 320 million jobs, or 10% of the total employment in 2018-19.The ICT market in Africa was valued at over USD32 billion in 2017.
    </p>

    <p>
        With a 34% annual average growth rate, the Nigerian Information and Communications Technology (ICT) sector is considered to be one of the fastest growing sectors in Nigeria, and also the second largest ICT market in Africa. In Q2 of 2019, the sector contributed14.57% to Nigeria's real GDP. 
        The industry is spearheaded by the rapid increase in the number of internet users, increasing demand for mobile applications from the service sectors and the rise of financial technology (Fin Tech) companies.
    </p>
    
    <p>
        No doubt, innovations with unique method of approaching issues like Tratrust, coupled with the current realities, is critically needed more than ever before. With our talented group of individuals, receptive outlooks and customer centric in nature, we will remodel the industry and open new socio-economic and political development, investment potential and certainly job creation.
    </p>
</div>
</mat-dialog-content>