<!-- Agents Card -->
<mdb-card class="p-0 mb-3 mt-0 mx-0" *ngIf="agent">
  <div class="view overlay zoom waves-light p-2" mdbWavesEffect>
    <!-- Card img -->
    <img class="rounded hoverable image" [src]="agent.profileImage" [alt]="agent.surname">
    <a>
      <div class="mask rgba-red-light white-text text-break text-responsive" style="border-radius: 6px;">
<!--         <ul class="" style="margin-left:-40px;">
          <li>{{agent.phoneNumber}}</li>
          <li>{{agent.email}}</li>
        </ul> -->
      </div>
    </a>
  </div>
  <!--Card content-->
  <mdb-card-body class="pb-0 px-0">
    <!--Title-->
    <mdb-card-title>
      <h6 class="app-text-color" style="margin-top: -15px; margin-bottom: -7px"><strong>{{agent.surname | uppercase}}
          {{agent.firstName | titlecase}} {{agent.middleName | titlecase | slice: 0:1}}</strong></h6>
      <sub><strong>{{agent.agentReferenceNumber}}</strong></sub>
    </mdb-card-title>

    <!-- Rating Component -->
    <app-rating [agent]='agent'></app-rating>

  </mdb-card-body>
</mdb-card>
<!-- End of Agents Card -->