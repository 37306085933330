<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Dr. Yahaya Ibrahim <br/>(Director Research and Development)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Yahaya Ibrahim,</strong> a Lecturer at the Department of Physics, Umaru Musa Yar’adua University (UMYU), Katsina. 
    </p>

    <p>
        He Graduated With B.Sc. (Hons) Physics from the Ahmadu Bello University, Zaria and M.Sc. 
        Theoretical Physics at the African University of Science And Technology, (AUST) Abuja. 
        He was subsequently granted a competitive teaching and research fellowship by the university of Bristol, 
        United Kingdom, where he completed his Doctoral studies in Mathematical Physics. 
        He later moved to the University de Montpellier, France, for his postdoctoral research.
    </p>
    
    <p>
        Dr. Yahaya Ibrahim has more than a decade of experience in academic teaching and research. 
        He specializes in the statistical physics of complex networks; from the collective behavior of bacterial cells to the dynamics of communication networks. 
        He also has a keen interest in machine learning, digital technologies and econo-physics.
    </p>

</mat-dialog-content>