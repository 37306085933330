import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from "@angular/router";
import { environment } from '../environments/environment';
import { FragmentPolyfillDirective } from "./fragment-polyfill.directive";
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AppMaterialModule } from './app-material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TeamModule } from "./board/team-module/team.module";
/* Components */
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MainComponent } from './main/main.component';
import { BackgroundComponent } from './background/background.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { BoardComponent } from './board/board.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';

import { AgentsComponent } from './agents/agents.component';
import { AgentsListComponent } from './agents/agents-list/agents-list.component';
import { RatingComponent } from './agents/rating/rating.component';
import { FlightbookingComponent } from './flightbooking/flightbooking.component';
import { HotelbookingComponent } from './hotelbooking/hotelbooking.component';

import { LoginComponent } from './login/login.component';
import { DARComponent } from './dar/dar.component';
import { BgMoreComponent } from './background/bg-more/bg-more.component';

import {routes} from './app-routing.module';
@NgModule({
  declarations: [
    AppComponent,
    FragmentPolyfillDirective,
    NavbarComponent,
    FooterComponent,
    MainComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    AgentsComponent,
    LoginComponent,
    AgentsListComponent,
    RatingComponent,
    FlightbookingComponent,
    DARComponent,
    HotelbookingComponent,
    BackgroundComponent,
    ContactComponent,
    OurServicesComponent,
    BoardComponent,
    BgMoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    //FragmentPolyfillDirective, 
     FormsModule, 
     ReactiveFormsModule,
     AngularFirestoreModule,
     AppMaterialModule,
     MatDialogModule,
     RouterModule.forRoot(
      routes,
      {
          // Tell the router to use the HashLocationStrategy.
          useHash: true,
          				// Enable the Angular 6+ router features for scrolling and anchors. The
				// polyfill will assume that the basic fragment support already exists.
				scrollPositionRestoration: "enabled",
				anchorScrolling: "enabled",
				enableTracing: false
      }
  ),
     BrowserAnimationsModule,
     TeamModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],  
  providers: [{ provide: SETTINGS, useValue: {} }],
  bootstrap: [AppComponent]
})
export class AppModule { }
