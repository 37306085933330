<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Haruna Yunusa Sa'eed (Chairman)</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong> Haruna Yunusa Sa’eed</strong> is a financial management expert with several years of experience. 
        He obtained his Bachelors’ Degree (BSc. Accounting) and Master of Science (MSc. Accounting & Finance) from the Ahmadu Bello University, Zaria in 1983 and 1987 respectively. 
        He is a professional member of the Association of National Accountant of Nigeria. In addition, he has obtained several professional certifications from the Harvard University, Boston, USA, the Duke University Durhan, North Carolina, USA, Learning Tree International Canada, Global Training Consulting, London, CWC School for Energy, Houston, USA, the University of Pennsylvania, USA, the Columbia University Business School Executive Education, USA amongst others.
    </p>

    <p>
        Aside being a financial expert, Haruna Yunusa Sa’eed is an academician that have served as a lecturer, Department of Accounting, Ahmadu Bello University, Zaria between 1986 and 1993.

    </p>

    <p>
        Haruna Yunusa Sa’eed has gathered a lot of experience in financial management as he was the training manager, 
        Nigeria Universal Bank Ltd between 1993 and 1994, the General Manager, Kaduna State Industrial & Financial Co. Ltd, 1994-1995, Accountant General, Kaduna State Ministry of Finance, 1995-2006, the Executive Secretary, Nigeria Extractive Industry Transparency, 2008-2010 and currently a Non-Executive Director, Premium Pension Limited.
    </p>



</mat-dialog-content>