<!-- <div class="bg"></div> -->

<!--Main Navigation-->
<header class="intro-header">

    <!--Intro Section-->
    <section class="view intro-2">
      <div class="bg mask rgba-gradient">
        <div class="container-fluid flex-center m-0 p-0">
          <div class="d-flex align-items-center content-height w-100">
            <div class="row flex-center p-0 m-0 w-100">

              <div class="p-0 m-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <!--Form-->
                <mat-card class="wow fadeInRight white mat-card" style="background-image: url('../../assets/bg/bg1.jpg');" data-wow-delay="0.3s">
                  <mat-card-title>
                    <!--Header-->
                    <div class="text-center">
                      <h3 class="text-danger">
                        <span class="material-icons text-danger">flight_takeoff</span> Search and Book Cheap Flight For Your Tour</h3>
                      <hr class="hr-dark">
                    </div>
                  </mat-card-title>
                  <mat-card-content class="mat-card-content" >

                    <!--Body-->
<!-- Small background input -->
<mat-tab-group>
  *************************************************************************************
    <mat-tab label="Return"> 
      <mat-form-field class="location" appearance="fill">
        <mat-label>From:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="location" appearance="fill">
        <mat-label>To:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Departure Date</mat-label>
        <input matInput [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
      
      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Return Date</mat-label>
        <input matInput [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="dark"></mat-datepicker>
      </mat-form-field>


      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel class="" style="width: 100%;">
          <mat-expansion-panel-header >
            <mat-panel-title>
              Passengers:
            </mat-panel-title>
            <mat-panel-description>
              Select number of passengers
            </mat-panel-description>
            <mat-icon >groups</mat-icon>
          </mat-expansion-panel-header>
      
          <mat-grid-list class="" cols="2" rowHeight="3:1">

            <mat-grid-tile class="">Adult (12yrs +)</mat-grid-tile>
            <mat-grid-tile class="">
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Children (2-11yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Infant (Under 2yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

        </mat-expansion-panel>
        </mat-accordion>

      
      <mat-form-field appearance="fill" style="width: 95%;">
        <mat-label>Cabin Class:</mat-label>
        <mat-select>
          <mat-option value="item1">
            Economy 
          </mat-option>
          <mat-option value="item2">
            Premium Economy
          </mat-option>
          <mat-option value="item3">
            Business Class
          </mat-option>
          <mat-option value="item4">
            First Class
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-tab>
  **************************************************************************************************
    <mat-tab label="One Way"> 
      
      <mat-form-field class="location" appearance="fill">
        <mat-label>From:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="location" appearance="fill">
        <mat-label>To:</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Departure Date</mat-label>
        <input matInput [matDatepicker]="picker3">
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
      
      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Return Date</mat-label>
        <input matInput [matDatepicker]="picker4" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
        <mat-datepicker #picker4 color="dark"></mat-datepicker>
      </mat-form-field>


      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel class="" style="width: 100%;">
          <mat-expansion-panel-header >
            <mat-panel-title>
              Passengers:
            </mat-panel-title>
            <mat-panel-description>
              Select number of passengers
            </mat-panel-description>
            <mat-icon >groups</mat-icon>
          </mat-expansion-panel-header>
      
          <mat-grid-list class="" cols="2" rowHeight="3:1">

            <mat-grid-tile class="">Adult (12yrs +)</mat-grid-tile>
            <mat-grid-tile class="">
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Children (2-11yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Infant (Under 2yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

        </mat-expansion-panel>
        </mat-accordion>

      
      <mat-form-field appearance="fill" style="width: 95%;">
        <mat-label>Cabin Class:</mat-label>
        <mat-select>
          <mat-option value="item1">
            Economy 
          </mat-option>
          <mat-option value="item2">
            Premium Economy
          </mat-option>
          <mat-option value="item3">
            Business Class
          </mat-option>
          <mat-option value="item4">
            First Class
          </mat-option>
        </mat-select>
      </mat-form-field>
     
    </mat-tab>
  ****************************************************************************************************
    <mat-tab>  
      <ng-template mat-tab-label>
        Multi-City
        <a (click)="addFlight()"><span class="material-icons">
          add_circle_outline
          </span>
        </a>     
      </ng-template>

    <form [formGroup]="multiCityForm" (ngSubmit)="onSubmit()">


    <div formArrayName="flights">
      
      <ng-container *ngFor="let flight of flights().controls; let i=index" [formGroupName]="i" >

        <mat-chip-list aria-label="Fish selection">
          <mat-chip>Flight {{1+i}}</mat-chip>
        </mat-chip-list>
        
      <mat-form-field class="location" appearance="fill">
        <mat-label>From:</mat-label>
        <input matInput formControlName="depart">
      </mat-form-field>

      <mat-form-field class="location" appearance="fill">
        <mat-label>To:</mat-label>
        <input matInput formControlName="dest">
      </mat-form-field>

      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Departure Date</mat-label>
        <input matInput [matDatepicker]="picker5">
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-datepicker #picker5></mat-datepicker>
      </mat-form-field>
      
      <mat-form-field class="date" color="dark" appearance="fill">
        <mat-label>Return Date</mat-label>
        <input matInput [matDatepicker]="picker6">
        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
        <mat-datepicker #picker6 color="dark"></mat-datepicker>
      </mat-form-field>

<!--       </ng-container>
      <ng-container *ngFor="let flight of flights().controls; let i=index" > -->
      <div class="pull-right" *ngIf="i<6" >
      <a (click)="removeFlight(i)"><span class="material-icons">
        remove_circle_outline
        </span>
      </a>
      <a *ngIf="i<6" (click)="addFlight()"><span class="material-icons">
        add_circle_outline
        </span>
      </a>
      </div>

      </ng-container>
    </div>
  


      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel class="" style="width: 100%;">
          <mat-expansion-panel-header >
            <mat-panel-title>
              Passengers:
            </mat-panel-title>
            <mat-panel-description>
              Select number of passengers
            </mat-panel-description>
            <mat-icon >groups</mat-icon>
          </mat-expansion-panel-header>
      
          <mat-grid-list class="" cols="2" rowHeight="3:1">

            <mat-grid-tile class="">Adult (12yrs +)</mat-grid-tile>
            <mat-grid-tile class="">
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Children (2-11yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Infant (Under 2yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

      
        </mat-expansion-panel>
        </mat-accordion>

      
      <mat-form-field appearance="fill" style="width: 95%;">
        <mat-label>Cabin Class:</mat-label>
        <mat-select>
          <mat-option value="item1">
            Economy 
          </mat-option>
          <mat-option value="item2">
            Premium Economy
          </mat-option>
          <mat-option value="item3">
            Business Class
          </mat-option>
          <mat-option value="item4">
            First Class
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    </mat-tab>

<!--     <mat-tab label="Fourth"> 
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Passengers:
            </mat-panel-title>
            <mat-panel-description>
              Select passengers and no. of passengers
              <mat-icon>groups</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
          <mat-grid-list class="" cols="2" rowHeight="3:1">

            <mat-grid-tile>Adult (12yrs +)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Children (2-11yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

            <mat-grid-tile>Infant (Under 2yrs)</mat-grid-tile>
            <mat-grid-tile>
              <div class="def-number-input number-input safari_only">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" class="minus"></button>
                <input class="quantity" min="0" name="quantity" value="1" type="number">
                <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
              </div>
            </mat-grid-tile>

          </mat-grid-list>

      
        </mat-expansion-panel>
        </mat-accordion>

      
    </mat-tab> -->
  </mat-tab-group>
  


                    <div class="text-center mt-1">
                      <button mat-raised-button color="warn">Search Flight</button>
                    </div>
                  </mat-card-content>
                </mat-card>
                <!--/.Form-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </header>
  <!--Main Navigation-->