import { Component, OnInit, Input } from '@angular/core';
import { Agents } from '../agents.model';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() agent: Agents;

  constructor() { }

  ngOnInit() {
  }

}
