<span>
    <i class="fa fa-close close-icon" [mat-dialog-close]="true"></i>
    <h2 mat-dialog-title class="hd ">Hassan Abubakar Opeyemi <br/>(Head Web Development and General Maintenance )</h2>    

</span>
<mat-dialog-content class="mat-typography text-justify bg animated zoomIn">
    <p>
        <strong>H.A Opeyemi</strong> is a self-employed Web/Software Engineer (Full stack Developer) With Extensive Experience and Management Skills. 
    </p>

    <p>
        He is a Web/Software Engineer, having developed his skills and experience. 
        He holds an International Diploma and Advanced International Diploma in Information & Communication Technology and Network & Computer Security respectively. 
        He has a B.Sc. Computer Science (ESAE, University) and M.Sc. Information Technology , National Open University of Nigeria (NOUN). He is a Web/Software Engineer in Damat and Sons Global Limited with more than 5 years experience. 
    </p>
    
    <p>
        Opeyemi is a Networking Cabling Specialist (Copper Base System and Fiber Optic Base System) and professionally erudite in Desktop Publishing,
        Integrated System and Voice Messaging (ISVM), Computer Hardware and Software Maintenance, Solar Power Installation, Closed Circuit Television (CCTV) Installation and 
        Wireless Networking Concept with WRT Router Configuration. 
    </p>
    
    <p>
        Opeyemi has developed a couple of Front-End and Back-End Responsive Websites for Damat And Sons Global Limited.
         
    </p>

</mat-dialog-content>