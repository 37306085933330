import { Component, OnInit, Input } from '@angular/core';
import { Agents } from './agents.model';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {
 
  @Input() agent: Agents; /* Fecth Agent Model Firestore data */

  constructor() { }

  ngOnInit() {
  }

}